<template>
  <div class="TimeShower">
    <div>
      当前北京时间：
      <div>{{ date }} {{ time }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TimeShower',
  data () {
    return {
      time: '',
      date: ''
    }
  },
  mounted () {
    this.Timer()
  },
  methods: {
    Timer () {
      var timerID = setInterval(this.updateTime, 1000)
      this.updateTime()
      return timerID
    },
    updateTime () {
      var week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      var cd = new Date()
      this.time =
        this.zeroPadding(cd.getHours(), 2) +
        ':' +
        this.zeroPadding(cd.getMinutes(), 2) +
        ':' +
        this.zeroPadding(cd.getSeconds(), 2)
      this.date =
        this.zeroPadding(cd.getFullYear(), 4) +
        '-' +
        this.zeroPadding(cd.getMonth() + 1, 2) +
        '-' +
        this.zeroPadding(cd.getDate(), 2) +
        ' ' +
        week[cd.getDay()]
    },
    zeroPadding (num, digit) {
      var zero = ''
      for (var i = 0; i < digit; i++) {
        zero += '0'
      }
      return (zero + num).slice(-digit)
    }
  }
}
</script>
