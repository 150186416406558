<template>
  <div class="Login">
      <div>
        用户名：
        <input
        type="text"
        placeholder="请输入用户名"
        v-model="data.username">
      </div>
      <div>
        密码：
        <input
        type="password"
        placeholder="请输入密码"
        v-model="data.password">
      </div>
      <div>
          <button @click="loginaction">登录</button>
          <Toast v-if="toastData.showToast" :message="toastData.toastMessage" />
      </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { loginM } from '@/utils/main/login.js'
import Toast, { useToastEffect } from '@/components/public/Toast.vue'
import md5 from 'js-md5'

export default {
  name: 'Login',
  components: { Toast },
  setup () {
    const router = useRouter()
    const data = reactive({ username: '', password: '' })
    const { toastData, showToast } = useToastEffect()
    const loginaction = async () => {
        try {
            const md5pw = md5(data.password)
            const result = await loginM(data.username, md5pw)
            if (result.data.status) {
                sessionStorage.setItem('isLogin', result.data.session)
                router.replace({ name: 'Home' })
            } else {
                showToast('登录失败')
            }
            } catch (e) {
                showToast('请求失败')
            }
      }
    return { loginaction, data, toastData }
  }
}
</script>

<style>
</style>
