<template>
  <div class="Search">
    <div class="next_time">
        下一次开奖时间：
        <div class="next_time_data">{{ dataInfo.data }}</div>
        <div class="next_time_data">{{ dataInfo.week }}</div>
    </div>
    <div>
        <button @click="updateData">更新数据</button>
    </div>
    <Toast v-if="toastData.showToast" :message="toastData.toastMessage" />
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import { getNextTimeM, updateDataM } from '@/utils/main/home.js'
import Toast, { useToastEffect } from '@/components/public/Toast.vue'

const getNextTime = async (dataInfo, showToast) => {
    try {
        const result = await getNextTimeM()
        const weekList = { 0: '星期一', 1: '星期二', 2: '星期三', 3: '星期四', 4: '星期五', 5: '星期六', 6: '星期日' }
        dataInfo.data = result.data.dataInfo
        dataInfo.week = weekList[result.data.weekSign]
    } catch (e) {
        showToast('请求失败')
    }
}

export default {
  name: 'Search',
  components: { Toast },
  setup () {
      const { toastData, showToast } = useToastEffect()
      const dataInfo = reactive({ data: '', week: '' })
      onMounted(() => {
        getNextTime(dataInfo, showToast)
        })
      const updateData = async () => {
        try {
            const result = await updateDataM()
            if (result.data) {
                showToast('更新成功！')
            } else {
                showToast('无需更新！')
            }
        } catch (e) {
            showToast('请求失败！')
        }
      }
      return { toastData, dataInfo, updateData }
  }
}
</script>
<style lang="less">
.next_time {
    font-size: 20px;
    padding: 5px;
}
.next_time_data:extend(.next_time){
    color: red;
}
</style>
