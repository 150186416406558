<template>
  <div class="AnalysePredict">
        预测筛选范围：
            <input type="text" v-model='scopeNum'>
      <div>
        <button @click="getPredictResult">预测按钮</button>
        <button @click="clearPredictResult">消除</button>
      </div>
      <div>
        蓝球Top：
        <tr v-for="(item, index) in resultInfo.blueTop" :key="index">
            <td>
                号码：<span>{{ item[0] }} </span>
                次数：<span>{{ item[1] }} </span>
            </td>
        </tr>
        <br>
        红球Top：
        <tr v-for="(item, index) in resultInfo.redTop" :key="index">
            <td>
                号码：<span>{{ item[0] }} </span>
                次数：<span>{{ item[1] }} </span>
            </td>
        </tr>
        <br>
        整体预测结果：
        <li v-for="(item, index) in resultInfo.predictResult" :key="index">
            前区：{{ item["red"] }}
            后区：{{ item["blue"] }}
        </li>
      </div>
      <Toast v-if="toastData.showToast" :message="toastData.toastMessage" />
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { getPredictResultM } from '@/utils/main/home.js'
import Toast, { useToastEffect } from '@/components/public/Toast.vue'

export default {
  name: 'AnalysePredict',
  components: { Toast },
  setup () {
    const scopeNum = ref()
    const resultInfo = reactive({ blueTop: [], redTop: [], predictResult: [] })
    const { toastData, showToast } = useToastEffect()
    const getPredictResult = async () => {
      try {
        if (scopeNum.value === undefined) {
          scopeNum.value = 0
        }
        const result = await getPredictResultM(scopeNum.value)
        resultInfo.blueTop = result.data.blueTop
        resultInfo.redTop = result.data.redTop
        resultInfo.predictResult = result.data.predictResult
      } catch (e) {
        showToast('请求失败')
      }
    }
    const clearPredictResult = () => {
      resultInfo.blueTop = []
      resultInfo.redTop = []
      resultInfo.predictResult = []
    }
    return { scopeNum, resultInfo, toastData, getPredictResult, clearPredictResult }
  }
}
</script>
<style lang="less">
</style>
