<template>
  <div class=wrapper>
    <router-link to="/home">Home</router-link> |
    <router-link to="/about">About</router-link>
    <router-view/>
  </div>
  <div class=Footer>
    <div class='disclaimer'>该项目仅供练习使用</div>
    <div class='recordnums'>
      <a href='https://beian.miit.gov.cn'>浙ICP备2021023347号-1</a>
    </div>
  </div>
</template>

<style scope>
.wrapper {
  min-width: 100%;
}

.Footer {
  position:fixed;
  left:0px;
  bottom:20px;
  width:100%;
  height: 70px;
  /* z-index:999; */
}

.disclaimer {
  font-size: 50px;
}
.recordnums {
  font-size: 20px;
}
</style>
