<template>
  <div class="GetHistoryInfo">
    <div>
        <span>查询条件：</span>
    </div>
    <div>
      <div>
        检索范围：
        <input type="radio" v-model="data.range" value="0" @click='clearTest'>全部
        <input type="radio" v-model="data.range" value="1" checked="checked">部分
      </div>
      <div v-if="data.range ==='1'">
        检索模式：
        <input type="radio" v-model="model" value="1" checked="checked">最新范围
        <input type="radio" v-model="model" value="0">自定义范围
        <div v-if="model === '1'">
          范围：<input type="text" v-model='data.scope'>
        </div>
        <div v-else>
          <div>开始期数：<input type="text" v-model='data.startPeriod'></div>
          <div>结束期数：<input type="text" v-model='data.endPeriod'></div>
      </div>
      </div>
      <button @click="getQueryInfo">按钮</button>
      <button @click="clearQueryInfo">消除</button>
    </div>
    <div>
      <li v-for="(item, index) in queryRet" :key="index">
        {{ item }}
      </li>
    </div>
    <br>
    <Toast v-if="toastData.showToast" :message="toastData.toastMessage" />
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { getQueryInfoM } from '@/utils/main/home.js'
import Toast, { useToastEffect } from '@/components/public/Toast.vue'

export default {
  name: 'GetHistoryInfo',
  components: { Toast },
  setup () {
    const model = ref('1')
    const data = reactive({ range: '1', scope: 10, startPeriod: '', endPeriod: '' })
    const queryRet = reactive([])
    const { toastData, showToast } = useToastEffect()
    const getQueryInfo = async () => {
      try {
        queryRet.length = 0
        if (model.value === '1') {
          data.startPeriod = ''
          data.endPeriod = ''
        } else {
          data.scope = 0
        }
        const result = await getQueryInfoM(data.range, data.scope, data.startPeriod, data.endPeriod)
        const arr = result.data.list
        for (let i = 0; i < arr.length; i++) {
          queryRet.push(arr[i])
        }
      } catch (err) {
        showToast('请求失败')
      }
    }
    const clearQueryInfo = () => {
      queryRet.length = 0
    }
    const clearTest = () => {
      model.value = '1'
    }
    // watch(model, () => {
    //   if (model.value === '1') {
    //     data.startPeriod = ''
    //     data.endPeriod = ''
    //   } else {
    //     data.scope = 10
    //   }
    // })
    return { model, data, queryRet, toastData, clearTest, getQueryInfo, clearQueryInfo }
  }
}
</script>
