import axios from 'axios'

const env = 'prod'
// const env = 'local'

const environment = (val) => {
  switch (val) {
    case 'local':
      return 'http://127.0.0.1/api'
    case 'prod':
      return '/api'
    default:
      return ''
  }
}
export default (obj) => {
  return new Promise((resolve, reject) => {
    const { url, method, data = {}, other = {} } = obj
    /** 创建axios实例 **/
    const service = axios.create({
      baseURL: environment(env), // api的base_url
      timeout: 5000 // 请求超时时间
    })
    service.interceptors.request.use(
      (config) => {
        config.method === 'post'
          ? (config.data = JSON.stringify(config.data))
          : (config.params = { ...config.data })
        config.headers['Content-Type'] = 'application/json; charset=utf-8'
        return config
      },
      (error) => {
        return error
      }
    )
    service.interceptors.response.use(
      (response) => {
        return response.data
      },
      (error) => {
        return error
      }
    )
    const info = {
      method,
      url,
      data
    }
    for (const key in other) {
      info[key] = other[key]
    }
    service(info)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
