<template>
  <div class="home">
    <div>
      <TimeShower />
    </div>
    <div>
      <Search />
    </div>
    <GetHistoryInfo />
    <AnalysePredict />
    <h1>GOOD LUCK!</h1>
  </div>
</template>

<script>
import TimeShower from '@/components/TimeShower.vue'
import Search from '@/components/Search.vue'
import GetHistoryInfo from '@/components/GetHistoryInfo.vue'
import AnalysePredict from '@/components/AnalysePredict.vue'

export default {
  name: 'Home',
  components: {
    TimeShower,
    Search,
    GetHistoryInfo,
    AnalysePredict
  }
}
</script>
