import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

const routes = [
  {
    // 这里需要将根目录默认为Home，方便实现用户在保持登录 状态下再次登录时直接跳转至主页面
      path: '/',
      redirect: {
        name: 'Home'
      }
    },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/NotFound.vue')
  },
  {
      path: '/:pathMatch(.*)',
      redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isLogin = sessionStorage.getItem('isLogin')
  // next()   //至此就可以显示页面了
  // 做业务逻辑,如果是登录状态，就进行下一步（注意登录页面不可做这个判断）
  if (to.name === 'Login') {
    // 如果要去的页面是登录页面，就进行下一步，不做判断
    next()
  } else {
    isLogin ? next() : next('/login')
  }
})

export default router
