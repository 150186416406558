import request from '@/utils/request.js'

export async function getPredictResultM (value) {
    return await request({
        method: 'post',
        url: '/analysegetpredict',
        data: { scope: value }
    })
}

export async function getQueryInfoM (range, scope, startPeriod, endPeriod) {
    return await request({
        method: 'post',
        url: '/querylist',
        data: {
            flag: range,
            scope: scope,
            start: startPeriod,
            end: endPeriod
           }
    })
}

export async function getNextTimeM (fileName) {
    return await request({
        method: 'get',
        url: '/nextlotterytime'
    })
}

export async function updateDataM (range, scope, startPeriod, endPeriod) {
    return await request({
        method: 'post',
        url: '/write'
    })
}
